<template>

  <div class="grid" v-if="logado">
    
    <div class="col-12" v-if="mostraCpf">
      <div class="grid">
        <div class="col-12 md:col-4">
          <label  class="block mb-2" for="cpfcnpj">CPF / CNPJ<br><small style="font-weight: 600">(obrigatório p/ pagamento online)</small></label>
          <InputText
            id="cpfcnpj"
            type="text"
            v-model="cpfcnpj"
            class="w-full"
            :class="(fieldsError.some(v => v === 'cpfcnpj'))?'p-invalid':''"
          />
        </div>
      </div>
    </div>

    <div class="col-2">
        <label  class="block mb-2" for="cep">CEP</label>
        <InputMask
            id="cep"
            type="text"
            v-model="cep"
            class="w-full"
            mask="99.999-999"
            :class="(fieldsError.some(v => v === 'cep'))?'p-invalid':''"
            @blur="validaCep"
        />
    </div>
    <div class="col-4">
        <label  class="block mb-2" for="endereco">Endereço</label>
        <InputText
            id="endereco"
            type="text"
            v-model="endereco"
            class="w-full"
            :class="(fieldsError.some(v => v === 'endereco'))?'p-invalid':''"
        />
    </div>
    <div class="col-2">
        <label  class="block mb-2" for="numero">Número</label>
        <InputText
            id="numero"
            type="text"
            v-model="numero"
            class="w-full"
            :class="(fieldsError.some(v => v === 'numero'))?'p-invalid':''"
        />
    </div>
    <div class="col-4">
        <label  class="block mb-2" for="complemento">Complemento</label>
        <InputText
            id="complemento"
            type="text"
            v-model="complemento"
            class="w-full"
            :class="(fieldsError.some(v => v === 'complemento'))?'p-invalid':''"
        />
    </div>
    <div class="col-4">
        <label  class="block mb-2" for="bairro">Bairro</label>
        <InputText
            id="bairro"
            type="text"
            v-model="bairro"
            class="w-full"
            :class="(fieldsError.some(v => v === 'bairro'))?'p-invalid':''"
        />
    </div>
    <div class="col-4">
        <label  class="block mb-2" for="cidade">Cidade</label>
        <InputText
            id="cidade"
            type="text"
            v-model="cidade"
            class="w-full"
            :class="(fieldsError.some(v => v === 'cidade'))?'p-invalid':''"
        />
    </div>
    <div class="col-2">
        <label  class="block mb-2" for="estado">Estado</label>
        <InputText
            id="estado"
            type="text"
            v-model="estado"
            class="w-full"
            :class="(fieldsError.some(v => v === 'estado'))?'p-invalid':''"
        />
    </div>
    <div class="col-2">

        <Button
            class="p-button-success mt-4"
            label="Atualizar"
            icon="pi pi-check"
            iconPos="right"
            @click="alterarCadastro"
        />

    </div>

  </div>

  <div class="grid" v-else>

    <div class="col-12 text-center">
      <Button
        class="p-button-secondary"
        :label="(autenticacao)?'Autenticar-se':'Cadastre-se'"
        icon="pi pi-user"
        iconPos="right"
        @click="$emit('autenticar')"
      />
    </div>

  </div>

  <ConfirmDialog></ConfirmDialog>

</template>

<script>

import axios from 'axios';

export default {
  emits: [
    'atualizado',
    'autenticar',
    'loading'
  ],
  data() {

    return {

      // Controle de Apresentação
      autenticacao: true,
      logado: false,
      mostraCpf: false,
      fieldsError: [],

      // Formulário
      cpfcnpj: "",
      cep: "",
      endereco: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",

      latitude: 0,
      longitude: 0,

    }

  },
  watch: {

    '$root.user': {
      handler: function(user) {
        if(user) {
          this.logado = true;

          this.cpfcnpj = (typeof user.cpf != "undefined"?user.cpf:"");
          this.cep = user.cep;
          this.endereco = user.lougradouro;
          this.complemento = user.complemento;
          this.numero = user.numeroCasa;
          this.bairro = user.bairro;
          this.cidade = user.cidade;
          this.estado = user.uf;

          this.latitude = user.latitude;
          this.longitude = user.longitude;

        } else {
          this.logado = false;

          this.cpfcnpj = "";
          this.cep = "";
          this.endereco = "";
          this.complemento = "";
          this.numero = "";
          this.bairro = "";
          this.cidade = "";
          this.estado = "";

          this.latitude = 0;
          this.longitude = 0;

        }

        if(this.cpfcnpj == "")
          this.mostraCpf = true;

      },
      deep: true,
      immediate: true
    },

    '$root.empresa': {
      handler: function(empresa) {
        if(empresa != null) {
          this.autenticacao = empresa.config.autenticacao.habilitada;
        } else {
          this.autenticacao = true;
        }

      },
      deep: true,
      immediate: true
    },

  },
  methods: {

    async validaCep() {

      const idx = this.fieldsError.indexOf("cep");
      if(idx >= 0) this.fieldsError.splice(idx, 1);

      try {
        
        let response = await axios.get("https://brasilapi.com.br/api/cep/v1/" + this.cep);
        response = response.data;
      
        this.estado = response.state;
        this.cidade = response.city;
        this.bairro = response.neighborhood;
        this.endereco = response.street;
        
      } catch (error) {
        
        if (error.response.data.name == "CepPromiseError")
          this.fieldsError.push("cep");

      }

    },

    async alterarCadastro() {

      const self = this;

      let dados = {
        "cep": this.cep,
        "endereco": this.endereco,
        "numero": this.numero,
        "complemento": this.complemento,
        "bairro": this.bairro,
        "cidade": this.cidade,
        "estado": this.estado,
        "idEmpresa": this.$root.empresa.id,
        "latitude": this.latitude,
        "longitude": this.longitude,
      };

      if(this.cpfcnpj != "")
        dados.cpf = this.cpfcnpj;

      this.$confirm.require({
        message: 'Você se encontra no endereço informado?',
        header: 'GPS',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: async () => {

          dados.latitude = self.$root.gps.latitude;
          dados.longitude = self.$root.gps.longitude;

          self.$emit('loading', true);

          self.gravarCadastro(dados);

        },
        reject: async () => {

          self.$emit('loading', true);

          let response = await self.$apiMega.tokenToRequest(this.$root.empresa.id).get('/cepEnderecoGPS?logradouro=' + self.endereco + '&bairro=' + self.bairro + '&cidade=' + self.cidade + '&uf=' + self.estado + '&cep=' + self.cep.replace(/[^0-9]/g,''));
          response = response.data;

          if(response.success) {

            dados.latitude = response.gps.lat;
            dados.longitude = response.gps.lng;

            self.gravarCadastro(dados);

          } else {

            if(self.$root.gps != null) {

              dados.latitude = self.$root.gps.latitude;
              dados.longitude = self.$root.gps.longitude;

              self.gravarCadastro(dados);

            } else {

              self.$emit('loading', false);

              self.$toast.add({
                severity: (response.data.success)?"success":"error",
                summary: (response.data.success)?"Sucesso!":"ERRO!",
                detail: response.data.message,
                life: (response.data.success)?3000:5000,
              });

            }

          }

        }

      });

    },

    async gravarCadastro(dados) {

      const response = await this.$api.post("/usuarios", dados);
      const data = response.data;

      this.$emit('loading', false);

      if(data.success) {

        this.$emit("atualizado", dados);

      } else if(typeof data.fields != "undefined") {

        this.fieldsError = data.fields;

      }

      this.$toast.add({
        severity: (response.data.success)?"success":"error",
        summary: (response.data.success)?"Sucesso!":"ERRO!",
        detail: response.data.message,
        life: (response.data.success)?3000:5000,
      });

    }

  }

}
</script>

<style>

</style>